import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
// import { BlockText } from 'components/block-text/BlockText';
import { Container } from 'components/container/Container';
import { Intro } from 'components/intro/Intro';
import unified from 'unified';
import parse from 'remark-parse';
import remark2react from 'remark-react';
import { Highlight } from 'components/intro/Highlight';

// tslint:disable no-default-export
export default (params: {
  data: { page: { frontmatter: { description: string; title: string; label: string }; html: any } };
}) => {
  return (
    <>
      <Helmet title={params.data.page.frontmatter.label} />
      <Intro>
        <h1>{params.data.page.frontmatter.title}</h1>
        {
          unified()
            .use(parse)
            .use(remark2react, {
              remarkReactComponents: {
                strong: Highlight,
              },
            })
            .processSync(params.data.page.frontmatter.description).result
        }
      </Intro>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: params.data.page.html }}></div>
      </Container>
    </>
  );
};

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePage($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        label
      }
    }
  }
`;
